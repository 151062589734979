import _ from 'lodash'

export function generateArray(count: number, min: number, max: number, allowDuplicate: boolean = false) {
    const array: number[] = []
    while (array.length < count) {
        const valueToPush = _.random(min, max)
        if (allowDuplicate)
            array.push(valueToPush)
        else if (!array.includes(valueToPush)) {
            array.push(valueToPush)
        }
    }
    return array
}

export function getRandomNumber(min: number, max: number) {
    return _.random(min, max)
}
