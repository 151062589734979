import styled, { keyframes } from "styled-components";
import { fadeOut, fadeIn } from "react-animations";
import { ReactNode, useEffect, useState } from "react";

const fadeOutAnimation = keyframes`${fadeOut}`;
const fadeInAnimation = keyframes`${fadeIn}`;

const FadeOutDiv = styled.div`
  animation: 2s ${fadeOutAnimation} 1s forwards;
`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation} forwards;
`;

export function Swap({ from, to, ms=2000, label }: { from: ReactNode; to: ReactNode, ms?: number, label?:string }) {
  const [transition,setTransition] = useState('init');
  useEffect(()=>{
    setTransition('from')
  },[from,to])
  useEffect(() => {
    const timeout=setTimeout(() => {
      setTransition('to');
    }, ms);
    return ()=>{
      clearTimeout(timeout)
    }
  });
  const fadeOutElem = <FadeOutDiv>{from}</FadeOutDiv>
  const fadeInElem = <FadeInDiv>{to}</FadeInDiv>
  return (
    <>
      {transition === "from" ? fadeOutElem : fadeInElem }
    </>
  );
}
