import { Tag } from "antd";
import { singlyLinkedListToArray, SinglyListNode } from "./utils";
import React from "react";

export interface SinglyLinkedListProps {
  node: SinglyListNode | null;
  pointers?: { [pointer: string]: number };
  pointerColors?: { [pointer: string]: string };
}
export function SinglyLinkedList(props: SinglyLinkedListProps) {
  const { node, pointers, pointerColors } = props;
  const listItems = singlyLinkedListToArray(node);
  return (
    <div style={{ textAlign: "center" }}>
      {listItems.length === 0 && <><Tag color="red" style={{
        fontSize: "20px",
        padding: "5px 10px",
      }}>null</Tag><br /><br/></>}
      {listItems.map((item, index) => {
        const pointerNames =
          pointers &&
          Object.entries(pointers)
            .map((entry) => (entry[1] === index ? entry[0] : ""))
            .filter((item) => item !== "");
        let color = pointerNames?.length ? "blue" : "default";
        if (pointerColors && pointerNames) {
          color = pointerColors[pointerNames[pointerNames.length - 1]];
        }
        return (
          <React.Fragment key={index}>
            <div
              style={{
                display: "inline-block",
                width: "55px",
              }}
            >
              <Tag
                color={color}
                style={{
                  fontSize: "20px",
                  width: "50px",
                  height: "30px",
                  paddingTop: "5px",
                }}
                key={index}
              >
                {item}
              </Tag>
              <span style={{ fontSize: "22px" }}>
                {pointerNames?.length ? <>&#8593;</> : <>&nbsp;</>}
              </span>
              <br />
              {pointerNames?.length ? (
                <span>{pointerNames.join(", ")}</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            <div style={{ display: "inline-block" }}>
              <div
                style={{
                  fontSize: "20px",
                  marginLeft: "-8px",
                  marginBottom: "10px",
                }}
              >
                &#8594;
                {index === listItems.length - 1 && (
                  <span style={{ marginLeft: "5px" }}>null</span>
                )}
              </div>
              <br />
              <br />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
