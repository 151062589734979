import { AlgorithmConfig } from "../../../types/algorithm";
import { generateArray, getRandomNumber } from "../../../utils";
import { Array1DIteration } from '../../../components/IterationVisualizer'

export function* selectionSort(arr: number[]): Generator<Array1DIteration> {
    let min: number = 0, temp: number | null = null, count = 0
    const len = arr.length
    let i = 0, j = 0
    yield {
        array: [...arr],
        message: 'Initial Array',
        lineToHighlight: 2,
        variables: { len }
    }
    for (; i < len; i++) {
        min = i
        j = i + 1
        for (; j < len; j++) {
            count++
            if (arr[j] < arr[min]) {
                yield {
                    array: [...arr],
                    pointers: { min, i, j },
                    message: `array[j] < array[min] so assign min=j`,
                    lineToHighlight: 7,
                    iterationCount: count,
                    variables: { len }
                }
                min = j
            } else {
                yield {
                    array: [...arr],
                    pointers: { min, i, j },
                    message: `array[j] > array[min] so continue`,
                    lineToHighlight: 9,
                    iterationCount: count,
                    variables: { len }
                }
            }
        }
        yield {
            array: [...arr],
            pointers: { min, i, j: j - 1 },
            swap: [i, min],
            message: `Swap array[i] and array[min]`,
            lineToHighlight: 11,
            iterationCount: count,
            variables: { len }
        }
        temp = arr[i]
        arr[i] = arr[min]
        arr[min] = temp
    }
    yield {
        array: [...arr],
        message: 'Sorted Array',
        lineToHighlight: 16,
        variables: { len }
    }
    return arr
}


export const selectionSortConfig: AlgorithmConfig = {
  path: "sorting/selection-sort",
  title: 'Selection Sort',
  menu: {
    label: 'Selection Sort',
    isVisible: true,
    weight: 3
  },
  algorithm: selectionSort,
  defaultInputs: () => ({
    nums: generateArray(getRandomNumber(5, 10), 0, 99)
  }),
  codeText: `function selectionSort(inputArray: number[]): number[] {
        // Start
        const len = inputArray.length;
        for (let i = 0; i < len; i++) {
          let min = i;
          for (let j = i + 1; j < len; j++) {
            if (inputArray[j] < inputArray[min]) {
              min = j;
            }
          }
          // Need to swap array[i] with array[min]
          let temp = inputArray[i];
          inputArray[i] = inputArray[min];
          inputArray[min] = temp;
        }
        // Sorted Array
        return inputArray;
      }`,
  pointerColors: { "min": "green", i: "gold", j: "blue" }
}