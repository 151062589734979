import { createBrowserRouter } from "react-router-dom";
import { Home } from './pages/common/Home'
import { MainLayout } from './layouts/MainLayout'
import { NoLayout } from './layouts/NoLayout'
import { AlgorithmVisualizer } from "./components/AlgorithmVisualizer";
import { AlgorithmConfig } from "./types/algorithm";
import { sortingConfigs } from "./algorithms/sorting";
import { leetCodeConfigs } from "./algorithms/leetcode";

const createRoutes = (configs:AlgorithmConfig[])=>{
  return configs.map(config=>{
    return {
      path: config.path,
      element: <AlgorithmVisualizer config={config}/>
    }
  })
}

const homeRoute ={
  path: "/",
  element: <Home/>,
}

const routes=[
  {
    path: "about",
    element: <h2>About</h2>,
  },
  {
    path: "test",
    element: (
      <h1>Test page</h1>
    ),
  },
  ...createRoutes(sortingConfigs),
  ...createRoutes(leetCodeConfigs),
]

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [homeRoute, ...routes],
    errorElement: <MainLayout />
  },
  {
    path: "/no-layout",
    element: <NoLayout />,
    children: [
      {
        path: "/no-layout",
        element: <Home/>,
      },
      ...routes
    ]
  }
]);
