import './App.less';
import {
  RouterProvider
} from "react-router-dom";
import { router } from './Routes'

function App() {
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
