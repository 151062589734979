import { romanToInteger13Config } from "./13_RomanToIntiger/config";
import { twoSum1Config } from "./1_TwoSum/config";
import { reverseLinkedList206Config } from "./206_ReverseLinkedList/config";
import { moveZeroes283 } from "./283_MoveZeros/config";
import { reverseString344Config } from "./344_ReverseString/config";

export const leetCodeConfigs = [
    twoSum1Config,
    reverseString344Config,
    reverseLinkedList206Config,
    romanToInteger13Config,
    moveZeroes283
   ]