import { sample } from 'lodash'
import { Array1DIteration } from '../../../components/IterationVisualizer'
import { AlgorithmConfig } from '../../../types/algorithm'
import { generateArray, getRandomNumber } from '../../../utils'

function* twoSum(nums: number[], target: number): Generator<Array1DIteration> {
    const len = nums.length
    yield {
        array: [...nums],
        message: 'Initial Array',
        variables: { len, target },
        lineToHighlight: 2
    }
    let iterationCount = 0
    for (let i = 0; i < nums.length; i++) {
        for (let j = i + 1; j < nums.length; j++) {
            iterationCount++
            const sum = nums[i] + nums[j]
            if (sum === target) {
                yield {
                    array: [...nums],
                    iterationCount,
                    message: 'sum === target then return i and j',
                    pointers: { i, j },
                    variables: { len, sum, target, result: `[${[i, j].join(', ')}]` },
                    lineToHighlight: 7
                }
                return [i, j]
            } else {
                yield {
                    array: [...nums],
                    iterationCount,
                    message: 'sum !== target so continue',
                    pointers: { i, j },
                    variables: { len, sum, target },
                    lineToHighlight: 5
                }
            }

        }
    }
    return []
}

export const twoSum1Config: AlgorithmConfig = {
    path: "leetcode/1-two-sum",
    title: '1. Two Sum - LeetCode',
    menu:{
        label: 'Two Sum (#1)',
        isVisible: true,
        weight: 1
    },
    algorithm: twoSum,
    defaultInputs: () => {
        const signed = sample([-1, 0]) as number;
        const nums = generateArray(getRandomNumber(2, 10), signed * 99, 99);
        const sample1 = sample(nums) as number;
        let sample2 = sample1;
        while (sample1 === sample2) {
            sample2 = sample(nums) as number;
        }
        const target = sample1 + sample2;
        return {
            nums,
            target
        }
    },
    codeText: `function twoSum(nums: number[], target: number): number[] {
        // Start
        for (let i = 0; i < nums.length; i++) {
            for (let j = i + 1; j < nums.length; j++) {
                const sum = nums[i] + nums[j]
                if (sum === target)
                    return [i, j]
            }
        }
        // We won't reach here until invalid target
        return []
    }`,
    pointerColors: { i: "red", j: "blue" },
    problemStatement: `Given an array of integers  \`nums\` and an integer  \`target\`, return  _indices of the two numbers such that they add up to  \`target\`_.
    
    You may assume that each input would have  **_exactly_  one solution**, and you may not use the  _same_  element twice.
    
    You can return the answer in any order.
    
    **Example 1:**
    
    **Input:** nums = [2,7,11,15], target = 9
    **Output:** [0,1]
    **Explanation:** Because nums[0] + nums[1] == 9, we return [0, 1].
    
    **Example 2:**
    
    **Input:** nums = [3,2,4], target = 6
    **Output:** [1,2]
    
    **Example 3:**
    
    **Input:** nums = [3,3], target = 6
    **Output:** [0,1]
    
    **Constraints:**
    
    -   \`2 <= nums.length <= 104\`
    -   \`-109 <= nums[i] <= 109\`
    -   \`-109 <= target <= 109\`
    -   **Only one valid answer exists.**
    
    **Problem Link:** [https://leetcode.com/problems/two-sum/](https://leetcode.com/problems/two-sum/)
    `
}