import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { Outlet, useNavigate, useLocation, Link, useRouteError } from "react-router-dom";
import "./styles.less";
import { sortingMenuItems, leetcodeMenuItems } from "../../config/menuItems";
import { PAGE_TITLE_PREFIX } from "../../config/common";
import logo from "../../images/logo.png";

const { Header, Content, Footer, Sider } = Layout;

const sortingItems = sortingMenuItems
  .filter((item) => item.isVisible)
  .map(({ key, label }) => ({ key, label }));
const leetcodeItems = leetcodeMenuItems
  .filter((item) => item.isVisible)
  .map(({ key, label }) => ({ key, label }));

const topNavItems: MenuProps["items"] = [
  {
    label: "Home",
    key: "/",
  },
  {
    key: "leetcode",
    label: "Leetcode",
    children: [...leetcodeItems],
  },
  {
    key: "sorting",
    label: "Sorting",
    children: [...sortingItems],
  },
];

const sideNavItems: MenuProps["items"] = [
  {
    key: "leetcode",
    label: "Leetcode",
    children: [...leetcodeItems],
  },
  {
    key: "sorting",
    label: "Sorting",
    children: [...sortingItems],
  },
];

export function MainLayout() {
  const routeError = useRouteError()
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageTitle = [...sortingMenuItems, ...leetcodeMenuItems].find(
    (item) => item.key === pathname
  )?.label;
  if (pathname === "/") {
    document.title = `Home - ${PAGE_TITLE_PREFIX}`;
  } else if (pageTitle) {
    const isLeetCode = pathname.includes('leetcode')
    if(isLeetCode){
      document.title = `${pageTitle} | LeetCode - ${PAGE_TITLE_PREFIX}`;
    }else{
      document.title = `${pageTitle} - ${PAGE_TITLE_PREFIX}`;
    }
  } else {
    document.title = `${PAGE_TITLE_PREFIX} - Unknown Page - ${pathname}`;
  }
  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} width="150" alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["/"]}
          selectedKeys={[pathname]}
          items={topNavItems}
          onClick={({ key }) => {
            navigate(key);
          }}
        />
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Layout
          className="site-layout-background"
          style={{ padding: "24px 0" }}
        >
          <Sider
            className="site-layout-background"
            width={200}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
          >
            <Menu
              mode="inline"
              style={{ height: "100%" }}
              items={sideNavItems}
              selectedKeys={[pathname]}
              defaultOpenKeys={["sorting", "leetcode"]}
              onClick={({ key }) => {
                navigate(key);
              }}
            />
          </Sider>
          <Content style={{ padding: "0 24px", minHeight: 280 }}>
            {routeError ? <h2>{'404 - Invalid Path'}</h2>: <Outlet />}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()} Created by Team DevTok
      </Footer>
    </Layout>
  );
}
