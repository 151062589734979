import { SinglyLinkedListIteration } from '../../../components/IterationVisualizer'
import { SinglyListNode } from '../../../components/SinglyLinkedList/utils'
import { cloneDeep } from 'lodash'
import { AlgorithmConfig } from '../../../types/algorithm'
import { generateArray, getRandomNumber } from '../../../utils'

export function* reverseList(head: SinglyListNode | null): Generator<SinglyLinkedListIteration> {
    let prev: SinglyListNode | null = null
    let curr: SinglyListNode | null = head
    let next: SinglyListNode | null = null
    yield {
        singlyLinkedList: cloneDeep(prev),
        title: 'prev',
        message: 'Starting',
        additionalData: [
            {
                title: 'curr',
                node: cloneDeep(curr)
            },
            {
                title: 'next',
                node: cloneDeep(next)
            }
        ],
        lineToHighlight: 5
    }
    while (curr) {
        yield {
            singlyLinkedList: cloneDeep(prev),
            title: 'prev',
            message: 'Start of the loop',
            additionalData: [
                {
                    title: 'curr',
                    node: cloneDeep(curr)
                },
                {
                    title: 'next',
                    node: cloneDeep(next)
                }
            ],
            lineToHighlight: 6
        }
        next = curr.next
        yield {
            singlyLinkedList: cloneDeep(prev),
            title: 'prev',
            message: 'After next=curr.next',
            additionalData: [
                {
                    title: 'curr',
                    node: cloneDeep(curr)
                },
                {
                    title: 'next',
                    node: cloneDeep(next)
                }
            ],
            lineToHighlight: 7
        }
        curr.next = prev
        yield {
            singlyLinkedList: cloneDeep(prev),
            title: 'prev',
            message: 'After curr.next=prev',
            additionalData: [
                {
                    title: 'curr',
                    node: cloneDeep(curr)
                },
                {
                    title: 'next',
                    node: cloneDeep(next)
                }
            ],
            lineToHighlight: 8
        }
        prev = curr
        yield {
            singlyLinkedList: cloneDeep(prev),
            title: 'prev',
            message: 'After prev=curr',
            additionalData: [
                {
                    title: 'curr',
                    node: cloneDeep(curr)
                },
                {
                    title: 'next',
                    node: cloneDeep(next)
                }
            ],
            lineToHighlight: 9
        }
        curr = next
        yield {
            singlyLinkedList: cloneDeep(prev),
            title: 'prev',
            message: 'After curr=next',
            additionalData: [
                {
                    title: 'curr',
                    node: cloneDeep(curr)
                },
                {
                    title: 'next',
                    node: cloneDeep(next)
                }
            ],
            lineToHighlight: 10
        }
    }
    yield {
        singlyLinkedList: cloneDeep(prev),
        title: 'prev',
        message: 'End',
        additionalData: [
            {
                title: 'curr',
                node: cloneDeep(curr)
            },
            {
                title: 'next',
                node: cloneDeep(next)
            }
        ],
        lineToHighlight: 12
    }
    return prev
}

export const reverseLinkedList206Config: AlgorithmConfig = {
    path:"leetcode/206-reverse-linked-list",
    title: '206. Reverse Linked List - LeetCode',
    menu:{
        label: 'Reverse Linked List (#206)',
        isVisible: true,
        weight: 206
    },
    algorithm: reverseList,
    defaultInputs: ()=>({
        nums:generateArray(getRandomNumber(5, 10), 0, 99)
    }),
    linkedListName: 'nums',
    codeText: `function reverseList(head: SinglyListNode | null): SinglyListNode | null {
        let prev:SinglyListNode|null = null
        let curr:SinglyListNode|null = head
        let next:SinglyListNode|null = null
        // Start
        while(curr){
            next=curr.next
            curr.next=prev
            prev=curr
            curr=next
        }
        return prev
    }`,
    pointerColors: { i: "red", j: "blue" },
    problemStatement: `Given the  \`head\`  of a singly linked list, reverse the list, and return  _the reversed list_.

    **Example 1:**
    
    ![](https://assets.leetcode.com/uploads/2021/02/19/rev1ex1.jpg)
    
    **Input:** head = [1,2,3,4,5]
    **Output:** [5,4,3,2,1]
    
    **Example 2:**
    
    ![](https://assets.leetcode.com/uploads/2021/02/19/rev1ex2.jpg)
    
    **Input:** head = [1,2]
    **Output:** [2,1]
    
    **Example 3:**
    
    **Input:** head = []
    **Output:** []
    
    **Constraints:**
    
    -   The number of nodes in the list is the range  \`[0, 5000]\`.
    -   \`-5000 <= Node.val <= 5000\`
    
    **Problem Link:** [https://leetcode.com/problems/reverse-linked-list/](https://leetcode.com/problems/reverse-linked-list/)
    `
}