import { Array1DIteration } from "../../../components/IterationVisualizer"
import { AlgorithmConfig } from "../../../types/algorithm"
import { getRandomNumber } from "../../../utils";

function integerToRoman(num:number) {    
    const digits = String(+num).split("")
    const key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
    "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
    "","I","II","III","IV","V","VI","VII","VIII","IX"]
    let roman_num = "",
    i = 3;
    while (i--)
    roman_num = (key[+(digits.pop() as string) + (i * 10)] || "") + roman_num;
    return Array(+digits.join("") + 1).join("M") + roman_num;
    }

function* romanToInt(s: string): Generator<Array1DIteration> {
    let integer = 0
    const romanLettersMap: { [index: string]: number } = {
        I: 1,
        V: 5,
        X: 10,
        L: 50,
        C: 100,
        D: 500,
        M: 1000
    }
    const romanLetters = s.split('')
    const { length } = romanLetters
    yield {
        array: [...romanLetters],
        message: 'Start',
        variables: { length, integer },
        lineToHighlight: 13
    }
    let i = 0
    while (i < length) {
        const firstLetter = romanLetters[i]
        const nextLetter = romanLetters[i + 1]
        yield {
            array: [...romanLetters],
            message: 'Loop',
            pointers: { i, 'i+1': i + 1 },
            variables: { length, firstLetter, nextLetter, integer },
            lineToHighlight: 18
        }
        const v1 = romanLettersMap[firstLetter]
        if (nextLetter) {
            const v2 = romanLettersMap[nextLetter]
            yield {
                array: [...romanLetters],
                message: 'if nextLetter !== undefined',
                pointers: { i, 'i+1': i + 1 },
                variables: { length, firstLetter, nextLetter, v1, v2, integer },
                lineToHighlight: 21
            }
            if (v1 < v2) {
                yield {
                    array: [...romanLetters],
                    message: 'if v1 < v2 , do integer += (v2 - v1)',
                    pointers: { i, 'i+1': i + 1 },
                    variables: { length, firstLetter, nextLetter, v1, v2, integer },
                    lineToHighlight: 22
                }
                integer += (v2 - v1)
                i++ 
            } else {
                yield {
                    array: [...romanLetters],
                    message: 'if v1 >= v2, then do integer += v1',
                    pointers: { i, 'i+1': i + 1 },
                    variables: { length, firstLetter, nextLetter, v1, v2, integer },
                    lineToHighlight: 25
                }
                integer += v1
            }
        } else {
            yield {
                array: [...romanLetters],
                message: 'if nextLetter === undefined, then integer += v1',
                pointers: { i, 'i+1': i + 1 },
                variables: { length, firstLetter, nextLetter, v1, integer },
                lineToHighlight: 28
            }
            integer += v1
        }
        i++
    }
    yield {
        array: [...romanLetters],
        message: 'Result',
        pointers: { i, 'i+1': i + 1 },
        variables: { integer },
        lineToHighlight: 33
    }
    return integer
}

export const romanToInteger13Config: AlgorithmConfig = {
    path:"leetcode/13-roman-to-integer",
    title: '13. Roman To Integer',
    menu:{
        label: 'Roman To Integer (#13)',
        isVisible: false,
        weight: 13
    },
    algorithm: romanToInt,
    defaultInputs: () => ({ s: integerToRoman(getRandomNumber(1,3999)) }),
    codeText: `function romanToInt(s: string) {
        let integer = 0
        const romanLettersMap: { [index: string]: number } = {
            I: 1,
            V: 5,
            X: 10,
            L: 50,
            C: 100,
            D: 500,
            M: 1000
        }
        const romanLetters = s.split('')
        // Start
        const { length } = romanLetters
        let i = 0
        while (i < length) {
            const firstLetter = romanLetters[i]
            const nextLetter = romanLetters[i + 1]
            const v1 = romanLettersMap[firstLetter]
            if (nextLetter) {
                const v2 = romanLettersMap[nextLetter]
                if (v1 < v2) {
                    integer += (v2 - v1)
                    i++ 
                } else {
                    integer += v1
                }
            } else {
                integer += v1
            }
            i++
        }
        return integer
    }`,
    pointerColors: { i: "red", 'i+1': "blue" },
    problemStatement: `Roman numerals are represented by seven different symbols: \`I\`,  \`V\`,  \`X\`,  \`L\`,  \`C\`,  \`D\`  and  \`M\`.

    |Symbol|Value  |
    |--|--|
    |  I|1  |
    |  V|5  |
    |  X|10  |
    |  L|50  |
    |  C|100  |
    |  D|500  |
    |  M|1000  |
    
    For example, \`2\`  is written as  \`II\` in Roman numeral, just two ones added together.  \`12\`  is written as \`XII\`, which is simply  \`X + II\`. The number  \`27\`  is written as  \`XXVII\`, which is  \`XX + V + II\`.
    
    Roman numerals are usually written largest to smallest from left to right. However, the numeral for four is not  \`IIII\`. Instead, the number four is written as  \`IV\`. Because the one is before the five we subtract it making four. The same principle applies to the number nine, which is written as  \`IX\`. There are six instances where subtraction is used:
    
    -   \`I\`  can be placed before  \`V\`  (5) and  \`X\`  (10) to make 4 and 9.
    -   \`X\`  can be placed before  \`L\`  (50) and  \`C\`  (100) to make 40 and 90.
    -   \`C\`  can be placed before  \`D\`  (500) and  \`M\`  (1000) to make 400 and 900.
    
    Given a roman numeral, convert it to an integer.
    
    **Example 1:**
    
    **Input:** s = "III"
    **Output:** 3
    **Explanation:** III = 3.
    
    **Example 2:**
    
    **Input:** s = "LVIII"
    **Output:** 58
    **Explanation:** L = 50, V= 5, III = 3.
    
    **Example 3:**
    
    **Input:** s = "MCMXCIV"
    **Output:** 1994
    **Explanation:** M = 1000, CM = 900, XC = 90 and IV = 4.
    
    **Constraints:**
    
    -   \`1 <= s.length <= 15\`
    -   \`s\`  contains only the characters  \`('I', 'V', 'X', 'L', 'C', 'D', 'M')\`.
    -   It is  **guaranteed** that  \`s\`  is a valid roman numeral in the range  \`[1, 3999]\`.
    
    **Problem Link:** https://leetcode.com/problems/roman-to-integer/`
}