import _ from "lodash";
import { AlgorithmConfig } from "../../../types/algorithm";
import { generateArray, getRandomNumber } from "../../../utils";
import { Array1DIteration } from "../../../components/IterationVisualizer";

function* moveZeroes(nums: number[]): Generator<Array1DIteration> {
    let i=0
    let j=1
    const {length} = nums
    yield {
        array: [...nums],
        message: 'Initial Array',
        variables: { length },
        pointers: { i, j },
        lineToHighlight: 4
    }
    while(j<length){
        yield {
            array: [...nums],
            message: 'j < length',
            pointers: { i, j },
            variables: { length },
            lineToHighlight: 5
        }
        if(nums[i] === 0 && nums[j] !== 0){
            yield {
                array: [...nums],
                message: 'if nums[i] === 0 && nums[j] !== 0, than swap nums[j] and nums[i]',
                pointers: { i, j },
                swap: [i,j],
                variables: { length },
                lineToHighlight: 6
            }
            const temp = nums[j]
            nums[j]=nums[i]
            nums[i]=temp
            i++
        }else if(nums[i] !== 0){
            yield {
                array: [...nums],
                message: 'else if nums[i] !== 0, than i++',
                pointers: { i, j },
                variables: { length },
                lineToHighlight: 11
            }
            i++
        }else{
            yield {
                array: [...nums],
                message: 'else if nums[i] === 0, than just do j++',
                pointers: { i, j },
                variables: { length },
                lineToHighlight: 14
            } 
        }
        j++
    }
    yield {
        array: [...nums],
        message: 'j >= length, so end of iteration',
        pointers: { i, j },
        variables: { length, i, j },
        lineToHighlight: 16
    }
}

export const moveZeroes283: AlgorithmConfig = {
    path: "leetcode/283-move-zeros",
    title: '283. Move Zeros - LeetCode',
    menu:{
        label: 'Move Zeros (#283)',
        isVisible: true,
        weight: 283
    },
    algorithm: moveZeroes,
    defaultInputs: () => {
        const nums:number[] = generateArray(getRandomNumber(5, 8), 0, 99)
        nums.push(0)
        nums.push(0)
        return {
            nums: _.shuffle(nums)
        };
    },
    codeText: `function moveZeroes(nums: number[]): void {
        let i=0
        let j=1
        const {length} = nums
        while(j<length){
            if(nums[i] === 0 && nums[j] !== 0){
                const temp = nums[j]
                nums[j]=nums[i]
                nums[i]=temp
                i++
            }else if(nums[i] !== 0){
                i++
            }
            j++
        }
    }`,
    pointerColors: { i: "red", j: "blue" },
    problemStatement: `Given an integer array  \`nums\`, move all  \`0\`'s to the end of it while maintaining the relative order of the non-zero elements.

    **Note**  that you must do this in-place without making a copy of the array.
    
    **Example 1:**
    
    **Input:** nums = [0,1,0,3,12]
    **Output:** [1,3,12,0,0]
    
    **Example 2:**
    
    **Input:** nums = [0]
    **Output:** [0]
    
    **Constraints:**
    
    -   \`1 <= nums.length <= 104\`
    -   \`-231 <= nums[i] <= 231 - 1\`
    
    **Problem Link:** [https://leetcode.com/problems/move-zeroes/](https://leetcode.com/problems/move-zeroes/)
    `
}