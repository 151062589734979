import {
  IterationVisualizer,
  Array1DIteration,
  SinglyLinkedListIteration,
} from "../../components/IterationVisualizer";
import { Card, Collapse } from "antd";
import { useGetInputParams } from "../../utils";
import { AlgorithmConfig } from "../../types/algorithm";
import { Markdown } from "../Markdown";

export function AlgorithmVisualizer({ config }: { config: AlgorithmConfig }) {
  const { Panel } = Collapse;
  const iterations: (Array1DIteration | SinglyLinkedListIteration)[] = [];
  const { inputList, inputObject } = useGetInputParams(config.defaultInputs(),config.linkedListName);
  for (let res of config.algorithm(...inputList)) {
    iterations.push(res);
  }
  return (
    <Card title={config.title}>
      {config.problemStatement && (
        <Collapse accordion style={{ marginBottom: "10px" }}>
          <Panel header="Problem Statement" key="1">
            <Markdown text={config.problemStatement} />
          </Panel>
        </Collapse>
      )}
      <IterationVisualizer
        iterations={iterations}
        pointerColors={config.pointerColors}
        code={config.codeText}
        inputs={inputObject}
      />
    </Card>
  );
}
