import React, { useRef, useState, useEffect } from "react";
import { Array1D } from "../Array1D";
import { SinglyListNode } from "../SinglyLinkedList/utils"
import { Button, Typography, Row, Col, Tag, Collapse, Switch } from "antd";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useLocation } from "react-router-dom";
import { SinglyLinkedList } from "../SinglyLinkedList";

export interface Array1DIteration {
  array: (number | string)[];
  lineToHighlight?: number;
  pointers?: { [pointer: string]: number };
  swap?: [number,number]; // swap positions
  split?: number[];
  iterationCount?: number;
  recursionCount?: number;
  message?: string;
  variables?: { [variableName: string]: number | string };
}
interface AdditionalSinglyLinkedList{
  title: string;
  node: SinglyListNode | null;
}
export interface SinglyLinkedListIteration {
  singlyLinkedList: SinglyListNode | null;
  title?: string;
  additionalData?: AdditionalSinglyLinkedList[];
  lineToHighlight?: number;
  pointers?: { [pointer: string]: number };
  split?: number[];
  iterationCount?: number;
  recursionCount?: number;
  message?: string;
  variables?: { [variableName: string]: number | string };
}

export function IterationVisualizer({
  iterations,
  pointerColors,
  code,
  inputs
}: {
  iterations: (Array1DIteration | SinglyLinkedListIteration)[];
  code: string;
  pointerColors?: { [pointer: string]: string };
  inputs: {[key:string]:any}
}) {
  const { pathname } = useLocation();
  const [showCode,setShowCode] = useState(false)
  const { Link } = Typography;
  const urlParams = !pathname.includes("no-layout") && Object.entries(inputs).map(([key,value])=>{
    if(typeof value !== 'number'){
      value = encodeURIComponent(JSON.stringify(value))
    }
    return `${key}=${value}`
  }).join('&')
  return (
    <>
      {pathname.includes("no-layout") ? (
        <IterationList
          iterations={iterations}
          pointerColors={pointerColors}
          code={code}
        />
      ) : (
        <>
          <div style={{display: 'inline'}}>
            <Link href={`/no-layout${pathname}?${urlParams}`} target="_blank">
              To Print
            </Link>
          </div>
          <div style={{display:'inline', marginLeft: '20px'}}>
            <Switch onChange={()=>{setShowCode(!showCode)}}/>
            <span style={{marginLeft: '5px'}}>Show Code</span>
          </div>

          <IterationOnClick
            iterations={iterations}
            pointerColors={pointerColors}
            code={code}
            showCode={showCode}
          />
        </>
      )}
    </>
  );
}

export function IterationList({
  iterations,
  pointerColors,
  code,
  noOfIterations,
  showReverse,
}: {
  iterations: (Array1DIteration | SinglyLinkedListIteration)[];
  code?: string;
  pointerColors?: { [pointer: string]: string };
  noOfIterations?: number;
  showReverse?: boolean;
}) {
  const { Text } = Typography;
  const iterationsToShow = iterations.map((iterationInfo, index) => (
    <React.Fragment key={index}>
      {(noOfIterations === undefined || noOfIterations >= index) && (
        <Col
          flex={500}
          style={{
            border: "1px solid grey",
            display: "inline-block",
            margin: "10px",
            textAlign: "center",
            padding: "10px",
          }}
        >
          <h3>
            {iterationInfo.iterationCount ? (
              `Iteration No.: ${iterationInfo.iterationCount}`
            ) : (
              <>&nbsp;</>
            )}
          </h3>
          <h3>
            {iterationInfo.recursionCount !== undefined ? (
              `Recursion No.: ${iterationInfo.recursionCount}`
            ) : (
              <>&nbsp;</>
            )}
          </h3>
          <div>
            {iterationInfo.message ? (
              <Text code style={{ fontSize: "18px" }}>
                {iterationInfo.message}
              </Text>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          { "array" in iterationInfo && <Array1D
            items={iterationInfo.array}
            pointers={iterationInfo.pointers}
            split={iterationInfo.split}
            pointerColors={pointerColors}
          />}
          {"singlyLinkedList" in iterationInfo && <>
          {iterationInfo.title && <span>{iterationInfo.title}</span>}
          <SinglyLinkedList node={iterationInfo.singlyLinkedList}/>
          {iterationInfo.additionalData && iterationInfo.additionalData.map(({title,node})=>{
            return <>
            <span>{title}</span>
            <SinglyLinkedList node={node}/><br/>
            </>
          })}
          </>}
          <br />
          <div style={{ marginBottom: "10px" }}>
            {iterationInfo.variables ? (
              <>
                {Object.entries(iterationInfo.variables).map(
                  ([key, value], index) => {
                    return (
                      <Tag
                        color="geekblue"
                        style={{ fontSize: "16px", padding: "5px" }}
                        key={index}
                      >{`${key} = ${value}`}</Tag>
                    );
                  }
                )}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </div>
        </Col>
      )}
    </React.Fragment>
  ));
  return (
    <>
      {code && (
        <Row>
          <Col span={24}>
            <SyntaxHighlighter
              style={docco}
              language={"typescript"}
              wrapLines={true}
              showLineNumbers
            >
              {code}
            </SyntaxHighlighter>
          </Col>
        </Row>
      )}
      <Row>{showReverse ? [...iterationsToShow].reverse() : iterationsToShow}</Row>
    </>
  );
}

export function IterationOnClick({
  iterations,
  pointerColors,
  code,
  showCode
}: {
  iterations: (Array1DIteration | SinglyLinkedListIteration)[];
  code: string;
  pointerColors?: { [pointer: string]: string };
  showCode: boolean;
}) {
  const { Text } = Typography;
  const { Panel } = Collapse;
  const [count, setCount] = useState(0);
  useEffect(()=>{
    setCount(0)
  },[iterations])
  const goFirst = () => {
    setCount(0);
  };
  const goLast = () => {
    setCount(iterations.length - 1);
  };
  const increment = () => {
    if (count < iterations.length - 1) setCount((c) => c + 1);
  };
  const decrement = () => {
    if (count > 0) setCount((c) => c - 1);
  };
  const iterationInfo = iterations[count];
  return (
    <>
      <Row>
        <Col span={showCode ? 14 : 24}>
          <div style={{ textAlign: "center" }}>
            <h3>
              {iterationInfo && iterationInfo.iterationCount ? (
                `Iteration No.: ${iterationInfo.iterationCount}`
              ) : (
                <>&nbsp;</>
              )}
            </h3>
            <h3>
              {iterationInfo && iterationInfo.recursionCount !== undefined ? (
                `Recursion No.: ${iterationInfo.recursionCount}`
              ) : (
                <>&nbsp;</>
              )}
            </h3>
            <div>
              {iterationInfo && iterationInfo.message ? (
                <Text code style={{ fontSize: "18px" }}>
                  {iterationInfo.message}
                </Text>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
            { iterationInfo && "array" in iterationInfo && <Array1D
              items={iterationInfo.array}
              pointers={iterationInfo.pointers}
              pointerColors={pointerColors}
              split={iterationInfo.split}
              swap={iterationInfo.swap}
            /> }
            {iterationInfo && "singlyLinkedList" in iterationInfo && <>
          {iterationInfo.title && <span>{iterationInfo.title}</span>}
          <SinglyLinkedList node={iterationInfo.singlyLinkedList}/>
          {iterationInfo.additionalData && iterationInfo.additionalData.map(({title,node},index)=>{
            return <React.Fragment key={index}>
            <span>{title}</span>
            <SinglyLinkedList node={node}/><br/>
            </React.Fragment>
          })}
          </>}
            <br />
            <div style={{ marginBottom: "10px" }}>
              {iterationInfo && iterationInfo.variables ? (
                <>
                  {Object.entries(iterationInfo.variables).map(
                    ([key, value], index) => {
                      return (
                        <Tag
                          color="geekblue"
                          style={{ fontSize: "16px", padding: "5px" }}
                          key={index}
                        >{`${key} = ${value}`}</Tag>
                      );
                    }
                  )}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </div>
            <Row id="iteration-nav">
              <Col flex={"auto"}>
                <Button onClick={goFirst} type="primary">
                  First
                </Button>
                <Button onClick={decrement} type="primary">
                  Previous
                </Button>
                <Button onClick={increment} type="primary">
                  Next
                </Button>
                <Button onClick={goLast} type="primary">
                  Last
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        {showCode && <Col span={10}>
          <SyntaxHighlighter
            style={docco}
            language={"typescript"}
            wrapLines={true}
            showLineNumbers
            lineProps={(lineNumber) => {
              let style: any = { display: "block" };
              if (iterationInfo && iterationInfo.lineToHighlight === lineNumber) {
                style.backgroundColor = "#FFFB6C";
              }
              return { style };
            }}
          >
            {code}
          </SyntaxHighlighter>
        </Col>}
      </Row>
      <Collapse accordion>
        <Panel header="Previous Iterations" key="1">
          <Row style={{ maxHeight: "350px", overflowY: "scroll" }}>
            <Col>
              <IterationList
                iterations={iterations}
                pointerColors={pointerColors}
                noOfIterations={count - 1}
                showReverse={true}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
}

export function AutoIterate({
  iterations,
  interval,
}: {
  iterations: Array1DIteration[];
  interval: number;
}) {
  const [count, setCount] = useState(0);
  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (count < iterations.length - 1) {
      timer.current = setTimeout(() => {
        setCount((c) => c + 1);
      }, interval);
    }
  });
  const reset = () => {
    clearTimeout(timer.current);
    setCount(0);
  };
  const iterationInfo = iterations[count];
  return (
    <>
      <div>
        {iterationInfo.iterationCount ? (
          `Iteration No.: ${iterationInfo.iterationCount}`
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <Array1D items={iterationInfo.array} pointers={iterationInfo.pointers} />
      <br />
      <Button onClick={reset}>Reset</Button>
    </>
  );
}
