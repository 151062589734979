import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

function CodeRenderer(props: any) {
  return (
    <code style={{ backgroundColor: "#e9f4f7", padding: "2px" }}>
      {props.children}
    </code>
  );
}

export function Markdown({ text }: { text: string }) {
  return (
    <ReactMarkdown
      components={{ a: LinkRenderer, code: CodeRenderer }}
      remarkPlugins={[remarkGfm]}
    >
      {text.replaceAll('\n    ','\n')}
    </ReactMarkdown>
  );
}
