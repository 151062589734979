import { Array1DIteration } from '../../../components/IterationVisualizer'
import { AlgorithmConfig } from '../../../types/algorithm'
import { generateArray, getRandomNumber } from '../../../utils'
/*
* Sort Number using Merge Sort
*/
export function* mergeSort(array: number[], partition: string = 'full', recursionCount = -1): Generator<Array1DIteration> {
    const len = array.length
    recursionCount++
    yield {
        array: [...array],
        message: partition === 'full' ? 'Start of the array' : `Start of the ${partition} array`,
        recursionCount,
        variables: { len, partition },
        lineToHighlight: 2
    }

    if (len <= 1) {
        yield {
            array: [...array],
            message: 'len <= 1 so return array',
            recursionCount,
            variables: { len, partition },
            lineToHighlight: 5
        }
        return array;
    }

    const middle = Math.floor(len / 2);
    const leftArray = array.slice(0, middle);
    const rightArray = array.slice(middle);

    yield {
        array: [...array],
        pointers: { middle },
        message: 'Partition the array as left and right',
        split: [middle],
        recursionCount,
        variables: { len, partition },
        lineToHighlight: 8
    }
    const leftSorted = yield* mergeSort(leftArray, 'left', recursionCount)
    const rightSorted = yield* mergeSort(rightArray, 'right', recursionCount)
    yield {
        array: [...array],
        message: 'Before merge left and right array partition',
        recursionCount,
        split: [middle],
        variables: {
            len,
            partition,
            leftSorted: `[${leftSorted.join(', ')}]`,
            rightSorted: `[${rightSorted.join(', ')}]`,
        },
        lineToHighlight: 11
    }
    const merged = yield* merge(leftSorted, rightSorted, partition, recursionCount)
    yield {
        array: [...merged],
        message: 'After merged',
        recursionCount,
        variables: { len, partition },
        lineToHighlight: 11
    }
    return merged
}

function* merge(leftSorted: number[], rightSorted: number[], partition: string, recursionCount: number): Generator<Array1DIteration> {
    const array: number[] = [];
    let left = 0;
    let right = 0;

    while (left + right < leftSorted.length + rightSorted.length) {
        yield {
            array: [...array],
            variables: { left, right, "leftSorted.length": leftSorted.length, "rightSorted.length": rightSorted.length, partition },
            pointers: { left, right },
            message: 'left + right < leftSorted.length + rightSorted.length',
            recursionCount,
            lineToHighlight: 18
        }
        const leftItem = leftSorted[left];
        const rightItem = rightSorted[right];

        if (leftItem === undefined) {
            yield {
                array: [...array],
                message: 'leftItem === undefined then push rightItem to the array and do right++',
                pointers: { left, right },
                recursionCount,
                variables: {
                    leftSorted: `[${leftSorted.join(', ')}]`,
                    rightSorted: `[${rightSorted.join(', ')}]`,
                    partition,
                    leftItem, rightItem
                },
                lineToHighlight: 21
            }
            array.push(rightItem);
            right++;
        }
        else if (rightItem === undefined) {
            yield {
                array: [...array],
                message: 'rightItem === undefined then push leftItem to the array and do left++',
                pointers: { left, right },
                recursionCount,
                variables: {
                    leftSorted: `[${leftSorted.join(', ')}]`,
                    rightSorted: `[${rightSorted.join(', ')}]`,
                    partition,
                    leftItem, rightItem
                },
                lineToHighlight: 25
            }
            array.push(leftItem);
            left++;
        }
        else if (leftItem < rightItem) {
            yield {
                array: [...array],
                message: 'leftItem < rightItem then push leftItem to the array and do left++',
                pointers: { left, right },
                recursionCount,
                variables: {
                    leftSorted: `[${leftSorted.join(', ')}]`,
                    rightSorted: `[${rightSorted.join(', ')}]`,
                    partition,
                    leftItem, rightItem
                },
                lineToHighlight: 29
            }
            array.push(leftItem);
            left++;
        }
        else {
            yield {
                array: [...array],
                message: 'leftItem >= rightItem then push rightItem to the array and do right++',
                pointers: { left, right },
                recursionCount,
                variables: {
                    leftSorted: `[${leftSorted.join(', ')}]`,
                    rightSorted: `[${rightSorted.join(', ')}]`,
                    partition,
                    leftItem, rightItem
                },
                lineToHighlight: 33
            }
            array.push(rightItem);
            right++;
        }

    }
    yield {
        array: [...array],
        message: 'Before return merged array',
        pointers: { left, right },
        recursionCount,
        variables: {
            leftSorted: `[${leftSorted.join(', ')}]`,
            rightSorted: `[${rightSorted.join(', ')}]`,
            partition
        },
        lineToHighlight: 38
    }
    return array;
}

export const mergeSortConfig: AlgorithmConfig = {
    path:"sorting/merge-sort",
    title: 'Merge Sort',
    menu:{
        label: 'Merge Sort',
        isVisible: false,
        weight: 4
    },
    algorithm: mergeSort,
    defaultInputs: () => ({
      nums: generateArray(getRandomNumber(5, 10), 0, 99)
    }),
    codeText: `function mergeSort(array: number[]): number[] {
        // Start
        const len = array.length
        if (len <= 1)
            return array;
        const middle = Math.floor(len / 2);
        const leftArray = array.slice(0, middle);
        const rightArray = array.slice(middle);
        const leftSorted = mergeSort(leftArray)
        const rightSorted = mergeSort(rightArray)
        return merge(leftSorted, rightSorted)
    }
    
    function merge(leftSorted: number[], rightSorted: number[]): number[] {
        const array: number[] = [];
        let left = 0;
        let right = 0;
        while (left + right < leftSorted.length + rightSorted.length) {
            const leftItem = leftSorted[left];
            const rightItem = rightSorted[right];   
            if (leftItem === undefined) {
                array.push(rightItem);
                right++;
            }
            else if (rightItem === undefined) {
                array.push(leftItem);
                left++;
            }
            else if (leftItem < rightItem) {
                array.push(leftItem);
                left++;
            }
            else {
                array.push(rightItem);
                right++;
            }
        }
        return array;
    }`,
    pointerColors: { middle: 'green', left: 'blue', right: 'blue' }
  }
