import { Tag, Typography } from "antd";
import React from "react";
import { Swap } from "../Animation/Swap";

export interface Array1DProps {
  items: (number | string)[];
  pointers?: { [pointer: string]: number };
  pointerColors?: { [pointer: string]: string };
  split?: number[];
  swap?: [number, number]
}

export function Array1D(props: Array1DProps) {
  const { items, pointers, pointerColors, split, swap } = props;
  const [fromIndex,toIndex] = swap || [-1,-1]
  const { Text } = Typography;
  return (
    <div style={{textAlign:'center'}}>
      <Text type="secondary">Array Length (n)={items.length}</Text>
      <div style={{ padding: "10px" }}>
        {items.map((item, index) => {
          const pointerNames =
            pointers &&
            Object.entries(pointers)
              .map((entry) => (entry[1] === index ? entry[0] : ""))
              .filter((item) => item !== "");
          let color = pointerNames?.length ? "blue" : "default";
          if (pointerColors && pointerNames) {
            color = pointerColors[pointerNames[pointerNames.length - 1]];
          }
          let arrayValue = item === " " ? <>&nbsp;</> : item
          if(index === fromIndex){
            arrayValue = <Swap label="j to j+1" from={<span>{items[fromIndex]}</span>} to={<span>{items[toIndex]}</span>}/>
          }
          else if(index === toIndex){
            arrayValue = <Swap label="j+1 to j" from={<span>{items[toIndex]}</span>} to={<span>{items[fromIndex]}</span>}/>
          }
          return (
            <React.Fragment key={index}>
              {split?.includes(index) && (
                <div
                  style={{ 
                    display: "inline-block",
                    borderLeft: '2px dashed blue',
                    position: 'relative',
                    top: '-75px',
                    left: '5px',
                    height:'50px'
                   }}
                >
                  &nbsp;
                </div>
              )}
              <div
                style={{
                  display: "inline-block",
                  width: "55px",
                  marginLeft: "10px",
                }}
              >
                <span>{index}</span>
                <Tag
                  color={color}
                  style={{
                    fontSize: "20px",
                    width: "50px",
                    height: "30px",
                    paddingTop: "5px",
                  }}
                >
                  {arrayValue}
                </Tag>
                <span style={{ fontSize: "22px" }}>
                  {pointerNames?.length ? <>&#8593;</> : <>&nbsp;</>}
                </span>
                <br />
                {pointerNames?.length ? (
                  <span>{pointerNames.join(", ")}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </React.Fragment>
          );
        })}
        {items.length === 0 && <h3>Empty Array [ ]</h3>}
      </div>
    </div>
  );
}
