import { isArray, isObject } from "lodash"
import { useSearchParams } from "react-router-dom"
import { arrayToSinglyLinkedList } from "../components/SinglyLinkedList/utils"

export const useInputParams = (paramName:string)=>{
    const [searchParams] = useSearchParams()
    const value = searchParams.get(paramName)
    if(value){
      return JSON.parse(value)
    }
    return undefined
  }

  export const useGetInputParams = (defaultInputs:{[key:string]:any},linkedListName?:string)=>{
    const [searchParams] = useSearchParams()
    const inputList:any[]=[]
    const inputObject:{[key:string]:any}={}
    Object.entries(defaultInputs).forEach(([key,defaultValue])=>{
      const data = searchParams.get(key)
      let value=defaultValue
      if(data){
        value=JSON.parse(data)
      }
      inputObject[key]=value
      if(isArray(value)){
        if(key === linkedListName){
          inputList.push(arrayToSinglyLinkedList([...value]))
        }else{
          inputList.push([...value])
        }        
      }else if(isObject(value)){
        inputList.push(Object.assign({},value))
      }else{
        inputList.push(value)
      }
    })
    return {
      inputList,
      inputObject
    }
  }
  