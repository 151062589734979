import type { MenuProps } from "antd";
import { sortingConfigs } from "../algorithms/sorting";
import { AlgorithmConfig } from "../types/algorithm";
import { leetCodeConfigs } from "../algorithms/leetcode";

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type MenuItem = ArrayElement<NonNullable<MenuProps["items"]>> & { key: string, label: string, isVisible: boolean, weight:number }

const getMenuItems = (algorithmConfigs:AlgorithmConfig[]):MenuItem[]=>{
  const menuItems:MenuItem[] = algorithmConfigs.map((config) => {
    const { path, menu:{ label, isVisible, weight } } = config
    return {
      key: `/${path}`,
      label,
      isVisible,
      weight
    }
  })
  
  return menuItems.sort((a,b)=>a.weight - b.weight)
}

export const sortingMenuItems: MenuItem[] = getMenuItems(sortingConfigs)

export const leetcodeMenuItems: MenuItem[] = getMenuItems(leetCodeConfigs)
