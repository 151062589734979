import { Link } from "react-router-dom";
import { leetcodeMenuItems, sortingMenuItems } from "../../../config/menuItems";

export function Home() {
  return (
    <>
      <h2>Home</h2>
      <h3>Sorting Algorithms</h3>
      {sortingMenuItems
        .filter((item) => item.isVisible)
        .map((item, index) => {
          return (
            <div key={index}>
              <Link to={item?.key}>{item?.label}</Link>
              <br />
            </div>
          );
        })}
      <h3>Leetcode Problems</h3>
      {leetcodeMenuItems
        .filter((item) => item.isVisible)
        .map((item, index) => {
          return (
            <div key={index}>
              <Link to={item.key}>{item.label}</Link>
              <br />
            </div>
          );
        })}
    </>
  );
}
