
//Definition for singly-linked list.
export class SinglyListNode {
    val: number
    next: SinglyListNode | null
    constructor(val?: number, next?: SinglyListNode | null) {
        this.val = (val===undefined ? 0 : val)
        this.next = (next===undefined ? null : next)
    }
}

export const arrayToSinglyLinkedList = (nums:number[]):SinglyListNode | null => {
    if(!nums.length)
        return null
    const singlyListNode:SinglyListNode = new SinglyListNode(nums[0])
        let currNode = singlyListNode
        let i=1
        while(i<nums.length){
            const node = new SinglyListNode(nums[i])
            currNode.next = node
            currNode = currNode.next
            i++
        }
        return singlyListNode
}

export const singlyLinkedListToArray = (singlyListNode:SinglyListNode|null):number[] => {
    if(!singlyListNode)
        return []
    const data:number[]=[]
    let currNode = singlyListNode
    data.push(currNode.val)
    while(currNode.next){
        currNode = currNode.next
        data.push(currNode.val)
    }
    return data
}

