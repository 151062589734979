import { AlgorithmConfig } from '../../../types/algorithm'
import { generateArray, getRandomNumber } from '../../../utils'
import { Array1DIteration } from '../../../components/IterationVisualizer'

export function* bubbleSort(inputArr: number[]): Generator<Array1DIteration> {
    const len = inputArr.length
    let i = 0,
        j = 0,
        count = 0
    yield {
        array: [...inputArr],
        message: 'Initial Array',
        lineToHighlight: 2,
        variables: {len}
    }
    for (i = 0; i < len; i++) {
        for (j = 0; j < len; j++) {
            count++
            if (inputArr[j] > inputArr[j + 1]) {
                yield {
                    array: [...inputArr],
                    pointers: {
                        j,
                        'j+1': j + 1
                    },
                    swap: [j, j+1],
                    message: `array[j] > array[j+1], so swap array[j] and array[j+1]`,
                    iterationCount: count,
                    lineToHighlight: 7,
                    variables: {len, i}
                }
                const temp = inputArr[j]
                inputArr[j] = inputArr[j + 1]
                inputArr[j + 1] = temp
            } else {
                let message = `array[j] < array[j+1], so need not to swap.`
                let lineToHighlight = 12
                if (!inputArr[j + 1]) {
                    message = `array[j+1] not available, so no action required.`
                    lineToHighlight = 11
                }
                yield {
                    array: [...inputArr],
                    pointers: {
                        j,
                        'j+1': j + 1
                    },
                    message,
                    iterationCount: count,
                    lineToHighlight,
                    variables: {len,i}
                }
            }
        }
    }
    yield {
        array: [...inputArr],
        message: 'Sorted Array',
        lineToHighlight: 15,
        variables: { len,i,j }
    }
    return inputArr
}

export function* bubbleSortRecursive(arr: number[], n: number=arr.length, iterationCount = 0, recursionCount = -1): Generator<Array1DIteration> {
    recursionCount++
    let count = 0;
    let i = 0
    yield {
        array: [...arr],
        iterationCount,
        pointers: iterationCount > 0 ? {i, "i+1":i+1}: undefined,
        message: recursionCount > 1 ? 'Recursion start' : '',
        variables: { n, count, recursionCount },
        lineToHighlight: 2
    }
    if (n === 1){
        yield {
            array: [...arr],
            message: 'n === 1 so return, array sorted.',
            iterationCount,
            variables: { n, count, recursionCount },
            lineToHighlight: 4
        }
        return;
    }
        
    for (; i < n - 1; i++) {
        iterationCount++
        if (arr[i] > arr[i + 1]) {
            yield {
                array: [...arr],
                pointers: {i, "i+1":i+1},
                message: 'array[i] > array[i+1] so swap array[i] and array[i+1]',
                iterationCount,
                variables: { n, count, recursionCount },
                lineToHighlight:8
            }
            let temp = arr[i];
            arr[i] = arr[i + 1];
            arr[i + 1] = temp;
            count++;
        }else {
            yield {
                array: [...arr],
                pointers: {i, "i+1":i+1},
                message: 'array[i] < array[i+1] so need not swap',
                iterationCount,
                variables: { n, count, recursionCount },
                lineToHighlight:13
            }
        }
    }


    if (count === 0){
        yield {
            array: [...arr],
            message: 'count === 0 so return, array sorted.',
            iterationCount,
            variables: { n, count, recursionCount },
            lineToHighlight: 17
        }
        return;
    }else{
        yield {
            array: [...arr],
            message: 'count !== 0 so call the recursive function',
            iterationCount,
            variables: { n, count, recursionCount },
            lineToHighlight: 19
        }
    }
        
    yield* bubbleSortRecursive(arr, n - 1, iterationCount, recursionCount);
}

export const bubbleSortConfig: AlgorithmConfig = {
    path: "sorting/bubble-sort",
    title: 'Bubble Sort',
    menu: {
        label: 'Bubble Sort',
        isVisible: true,
        weight: 1
    },
    algorithm: bubbleSort,
    defaultInputs: ()=>({
        nums: generateArray(getRandomNumber(5, 10), 0, 99)
    }),
    codeText: `function bubbleSort(inputArr: number[]):number[] {
        // Start
        const len = inputArr.length
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                if (inputArr[j] > inputArr[j+1]) {
                    // Need to swap
                    const temp = inputArr[j]
                    inputArr[j] = inputArr[j+1]
                    inputArr[j+1] = temp
                }
                // Need not to swap
            }
        }
        // Sorted Array
        return inputArr
      }`,
    pointerColors: { i: "red", j: "blue", "j+1": "blue" }
}

export const bubbleSortRecursiveConfig: AlgorithmConfig = {
    path: "sorting/bubble-sort-recursive",
    title: 'Bubble Sort - Recursive',
    menu: {
        label: 'Bubble Sort - Recursive',
        isVisible: false,
        weight: 2
    },
    algorithm: bubbleSortRecursive,
    defaultInputs: ()=>({
        nums: generateArray(getRandomNumber(5, 10), 0, 99)
    }),
    codeText: `function bubbleSortRecursive(arr: number[], n: number=arr.length) {
            // Start
            if (n === 1)
                return;
        
            let count = 0;
            for (let i = 0; i < n - 1; i++) {
                if (arr[i] > arr[i + 1]) {
                    let temp = arr[i];
                    arr[i] = arr[i + 1];
                    arr[i + 1] = temp;
                    count++;
                }
            }
        
            if (count === 0)
                return;
        
            bubbleSortRecursive(arr, n - 1)
        }`,
    pointerColors: { i: "blue", "i+1": "blue" }
}