import { Outlet, Link, useLocation } from "react-router-dom";
import { sortingMenuItems, leetcodeMenuItems } from "../../config/menuItems";
import { PAGE_TITLE_PREFIX } from "../../config/common";
import logo from "../../images/logo.png";

export function NoLayout() {
  const { pathname } = useLocation();
  const properPathname = pathname.replace("/no-layout", "");
  const pageTitle = [...sortingMenuItems, ...leetcodeMenuItems].find(
    (item) => item.key === properPathname
  )?.label;

  if (pathname === "/") {
    document.title = `Print - Home - ${PAGE_TITLE_PREFIX}`;
  } else if (pageTitle) {
    document.title = `Print - ${pageTitle} - ${PAGE_TITLE_PREFIX}`;
  } else {
    document.title = `Print - ${PAGE_TITLE_PREFIX} - Unknown Page - ${pathname}`;
  }

  return (
    <>
      <div style={{margin: '10px'}}>
        <Link to="/">
          <img src={logo} width="150" alt="logo" />
        </Link>
      </div>
      <Outlet />
    </>
  );
}
