import { sample } from 'lodash'
import { Array1DIteration } from '../../../components/IterationVisualizer'
import { AlgorithmConfig } from '../../../types/algorithm'

export function* reverseString(s: string[]): Generator<Array1DIteration> {
    yield { array: [...s], lineToHighlight: 2, message: 'Initial Array' }
    let count = 0
    let i = 0
    let j = s.length - 1
    while (i < j) {
        count++
        yield {
            array: [...s],
            pointers: { i, j },
            message: 'Swap array[i] and array[j]',
            lineToHighlight:6,
            iterationCount: count
        }
        const temp = s[i]
        s[i] = s[j]
        s[j] = temp
        i = i + 1
        j = j - 1
    }
    yield { array: [...s], pointers: { i, j }, message: 'Reversed String', iterationCount: count, lineToHighlight:13 }
    return s
}


export const reverseString344Config: AlgorithmConfig = {
    path: "leetcode/344-reverse-string",
    title: '344. Reverse String - LeetCode',
    menu: {
        label: 'Reverse String (#344)',
        isVisible: true,
        weight: 344
    },
    algorithm: reverseString,
    defaultInputs: () => {
        const arrayOfWords:string[] = [
            "HELLO WORLD",
            "COMPUTER",
            "TECHNOLOGY",
            "JAVASCRIPT",
            "TYPESCRIPT",
            "TUTORIAL",
            "PROGRAM",
          ];
          return {
            word:sample(arrayOfWords)?.split("") || []
          }
    },
    codeText: `function reverseString(s: string[]): string[] {
        // Start
        let i = 0
        let j = s.length - 1
        while (i < j) {
            // Swap array[i] and array[j]
            const temp = s[i]
            s[i] = s[j]
            s[j] = temp
            i++
            j--
        }
        // Reversed string
        return s
    }`,
    pointerColors: { i: "red", j: "blue" },
    problemStatement: `Write a function that reverses a string. The input string is given as an array of characters  \`s\`.

    You must do this by modifying the input array  [in-place](https://en.wikipedia.org/wiki/In-place_algorithm)  with  \`O(1)\`  extra memory.
    
    **Example 1:**
    
    **Input:** s = ["h","e","l","l","o"]
    **Output:** ["o","l","l","e","h"]
    
    **Example 2:**
    
    **Input:** s = ["H","a","n","n","a","h"]
    **Output:** ["h","a","n","n","a","H"]
    
    **Constraints:**
    
    -   \`1 <= s.length <= 105\`
    -   \`s[i]\`  is a  [printable ascii character](https://en.wikipedia.org/wiki/ASCII#Printable_characters).
    
    **Problem Link:** [https://leetcode.com/problems/reverse-string/](https://leetcode.com/problems/reverse-string/)
    `
}